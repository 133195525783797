// profileSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { progressCheck, progressStart } from "../../api";

const initialState = {
  checked: false,
  token: null,
  active: null,
  timer: {
    deductionInterval: 1,
    questionIsOver: false,
    testIsOver: false,
  },
  pinalties: {
    viewportChange: 0,
    screenNotFocused: 0,
    mouseNotFocused: 0,
    tabChange: 0,
    reloadPage: 0,
    notFullscreen: 0,
  },
  playCount: {}, // Pastikan playCount diinisialisasi sebagai objek
  activeTest: {
    current: null,
    question: null,
    test: null,
  }
};

export const handleProgressStart = createAsyncThunk(
  "assessment/handleProgressStart",
  async (candidate_test_id) => {
    const result = await progressStart(candidate_test_id); // Replace with your API endpoint
    return result.data;
  }
);

export const handleProgressCheck = createAsyncThunk(
  "assessment/handleProgressCheck",
  async () => {
    const result = await progressCheck(); // Replace with your API endpoint
    return result.data;
  }
);

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    setActive: (state, data) => {
      state.active = data.payload;
    },
    setClearAll: (state) => {
      state.active = null;
      state.token = null;
      state.timer = {
        deductionInterval: 1,
        questionIsOver: false,
        testIsOver: false,
      };
      state.pinalties = {
        viewportChange: 0,
        screenNotFocused: 0,
        mouseNotFocused: 0,
        tabChange: 0,
        reloadPage: 0,
        notFullscreen: 0,
      };
      state.activeTest = {
        current: null,
        question: null,
        test: null,
      }
    },
    setTimerDeducation: (state, data) => {
      state.timer.deductionInterval = data.payload;
    },
    setQuestionTimeOver: (state, data) => {
      state.timer.questionIsOver = data.payload;
    },
    setTestTimeOver: (state, data) => {
      state.timer.testIsOver = data.payload;
    },
    setIncreasePinaltiesViewport: (state) => {
      state.pinalties.viewportChange += 1;
    },
    setIncreasePinaltiesScreenBlur: (state) => {
      state.pinalties.screenNotFocused += 1;
    },
    setIncreasePinaltiesMouse: (state) => {
      state.pinalties.mouseNotFocused += 1;
    },
    setIncreasePinaltiesReloadPage: (state) => {
      state.pinalties.reloadPage += 1;
    },
    setIncreasePinaltiesNotFullscreen: (state) => {
      state.pinalties.notFullscreen += 1;
    },
    setDecreasePinaltiesScreenBlur: (state) => {
      if (state.pinalties.screenNotFocused > 0) {
        state.pinalties.screenNotFocused -= 1;
      }
    },
    setDecreasePinaltiesMouse: (state) => {
      if (state.pinalties.mouseNotFocused > 0) {
        state.pinalties.mouseNotFocused -= 1;
      }
    },
    setIncreasePinaltiesTab: (state) => {
      state.pinalties.tabChange += 1;
    },
    setClearPinalties: (state) => {
      state.pinalties.tabChange = 0;
      state.pinalties.viewportChange = 0;
      state.pinalties.mouseNotFocused = 0;
      state.pinalties.screenNotFocused = 0;
      state.pinalties.reloadPage = 0;
      state.pinalties.notFullscreen = 0;
    },
    setToken: (state, data) => {
      state.token = data.payload;
    },
    setPlayCount: (state, action) => {
      const { url, count } = action.payload;
      state.playCount[url] = count; // Pastikan playCount diakses sebagai objek
      return;
    },
    setActiveTestCurrent: (state, action) => {
      state.activeTest.current = action.payload;
    },
    setActiveTestQuestion: (state, action) => {
      state.activeTest.question = action.payload;
    },
    setActiveTest: (state, action) => {
      state.activeTest.test = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleProgressStart.fulfilled, (state, action) => {
      state.token = action.payload.token;
    });

    builder.addCase(handleProgressCheck.fulfilled, (state, action) => {
      state.checked = true;
      if (action.payload) {
        state.token = action.payload.token;
        state.active = action.payload.candidate_test_id;
        if (action.payload.progress.length > 0) {
          sessionStorage.setItem(
            "answers",
            JSON.stringify(action.payload.progress)
          );
          if (sessionStorage.getItem("latest_timer") == null) {
            sessionStorage.setItem("latest_timer", action.payload.time_left);
          }
        }
        if (action.payload.pinalties) {
          state.pinalties = action.payload.pinalties;
        }
      }
    });
  },
});

export const {
  setActive,
  setTimerDeducation,
  setQuestionTimeOver,
  setTestTimeOver,
  setIncreasePinaltiesViewport,
  setIncreasePinaltiesTab,
  setClearPinalties,
  setIncreasePinaltiesScreenBlur,
  setIncreasePinaltiesMouse,
  setDecreasePinaltiesScreenBlur,
  setDecreasePinaltiesMouse,
  setIncreasePinaltiesReloadPage,
  setIncreasePinaltiesNotFullscreen,
  setToken,
  setClearAll,
  setPlayCount,
  setActiveTestCurrent,
  setActiveTestQuestion,
  setActiveTest,
} = assessmentSlice.actions;
export default assessmentSlice.reducer;
