import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../Assets/bgAuth.webp";
import "../css/login.css";
import BtnSpinner from "../Component/BtnSpinner";
import PasswordInput from "../Component/PasswordInput";
import FooterGuest from "../Component/FooterGuest";
import { documentTitle, validationError } from "../helpers";
import { postLogin, postLoginNewPassword, postResetPassword } from "../api";
import { BRAND_HIGHR_2 } from "../constants";

const LoginPage = () => {
  let navigate = useNavigate();

  const [validations, setValidations] = useState({});
  const [tempToken, setTempToken] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [signing, setSigning] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [formPasswordPassed, setFormPasswordPassed] = useState(false);
  const handleEmailChange = (e) => {
    const value = e.target.value || "";
    setEmail(value);
  };

  // TEST 1
  const handlePasswordChange = (password) => {
    setPassword(password);
  };
  const handleNewPasswordChange = (newPassword) => {
    setNewPassword(newPassword);
  };
  const handleNewPasswordConfirmChange = (value) => {
    setNewPasswordConfirm(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleLogin = (e) => {
    const data = e.data;
    let defaultRedirect = "/dashboard";
    if (e.success) {
      localStorage.removeItem("candidate_invitation");
      if (e.data.reset_password) {
        setResetPassword(true);
        setSigning(false);
        setTempToken(e.data.token);
      } else {
        sessionStorage.setItem("user", JSON.stringify(e.data));
        let user = JSON.parse(sessionStorage.getItem("user"));
        if (!data.profile.phonenumber) {
          defaultRedirect = "/welcome";
        }
        toast.success("Logged in as " + user.email + " (" + user.name + ")", {
          toastId: "login-success-toast",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1250,
          onClose: () => navigate(defaultRedirect, { replace: false }),
        });
      }
    } else {
      if (e.status_code === 422) {
        setValidations(e.message);
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(e.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSigning(true);
    let invitation = "";
    if ("candidate_invitation" in localStorage) {
      invitation = localStorage.getItem("candidate_invitation");
    }

    const loginData = JSON.stringify({
      email: email,
      password: password,
      invitation: invitation,
    });

    try {
      const result = await postLogin(loginData);
      // if (!result.success) {
      // setSigning(false);
      // }
      handleLogin(result);
    } catch (error) {
      setSigning(false);
      console.log(error);
      toast.error("Invalid email / password", {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleLoginNewPassword = async (newPasswordLogin) => {
    setSigning(true);
    let invitation = "";
    if ("candidate_invitation" in localStorage) {
      invitation = localStorage.getItem("candidate_invitation");
    }

    const loginData = JSON.stringify({
      email: email,
      password: newPasswordLogin,
      invitation: invitation,
    });

    const result = await postLoginNewPassword(loginData);
    if (!result.success) {
      setSigning(false);
    }
    handleLogin(result);
  };

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    setSigning(true);
    const changePasswordData = JSON.stringify({
      password: newPassword,
      confirmpassword: newPasswordConfirm,
    });

    const result = await postResetPassword(changePasswordData, tempToken);
    if (result.success) {
      handleLoginNewPassword(newPassword);
    } else {
      setSigning(false);
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (newPassword != "" && newPasswordConfirm != "") {
      setFormPasswordPassed(true);
      const newValidations = { ...validations };
      if (newPassword.length < 8) {
        newValidations.newPassword = [validationError("password")];
      } else {
        newValidations.newPassword = [];
      }
      if (newPassword != newPasswordConfirm) {
        newValidations.newPasswordConfirm = [
          validationError("password_confirmation"),
        ];
      } else {
        newValidations.newPasswordConfirm = [];
      }
      setValidations(newValidations);
      // Cek jika semua properti dalam objek validasi kosong
      const allValid = Object.values(newValidations).every(
        (validationArray) => validationArray.length === 0
      );
      setFormPasswordPassed(allValid);
    } else {
      setFormPasswordPassed(false);
    }
  }, [newPassword, newPasswordConfirm]);

  useEffect(() => {
    documentTitle("Login");
  }, []);
  // TEST 2
  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  // CHANGE AT 19/06/2023
  return (
    <div style={myStyle}>
      <div className="container mx-auto pt-12 h-full flex flex-col md:justify-center">
        <ToastContainer />
        <div className="mx-auto px-4 flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left mb-6">
            <p className="text-xl md:text-2xl text-white-1 mb-[16px] font-normal">
              Welcome To
            </p>
            <p className="text-xl md:text-6xl text-white-1 leading-[110%] font-light md:max-w-sm">
              {BRAND_HIGHR_2}
            </p>
          </div>
          {!resetPassword ? (
            <>
              <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8">
                  <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                    Login To {BRAND_HIGHR_2}
                  </h1>
                  <form
                    className="bg-grey-lightest"
                    name="login"
                    onSubmit={handleSubmit}
                  >
                    <div className="w-full justify-center">
                      <div className="mb-5">
                        <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                          Email
                        </label>
                        <input
                          className="border w-full p-3 h-10 placeholder:text-sm rounded-lg  focus:outline-none focus:border-main-violate border border-line-grey"
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          onKeyDown={handleKeyDown}
                          onChange={handleEmailChange}
                          placeholder="Email"
                        />
                        {typeof validations.email != "undefined"
                          ? validations.email.map((e) => {
                              return (
                                <span className="input-error-message">{e}</span>
                              );
                            })
                          : ""}
                      </div>
                      <div className="mb-[16px]">
                        <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                          Password
                        </label>
                        <PasswordInput
                          onChange={handlePasswordChange}
                          onKeyDown={handleKeyDown}
                        />
                        {typeof validations.password != "undefined"
                          ? validations.password.map((e, key) => {
                              return (
                                <span key={key} className="input-error-message">
                                  {e}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                      <div className="flex justify-between mb-5">
                        <div className="flex justify-center">
                          {/* <div className="form-check form-check-inline">
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none transition duration-200 mt-1 align-left bg-no-repeat bg-center bg-contain float-left mr-[4px] cursor-pointer focus:border-white"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1"
                          />
                          <label className="form-check-label inline-block text-grey-darkest text-[12px] leading-[16.8px]">
                            Remember Me
                          </label>
                        </div> */}
                        </div>
                        <Link
                          to="/forgot-password"
                          className="text-grey-darkest hover:text-black text-[12px] leading-[16.8px] font-semibold mt-2 underline text-main-violate"
                        >
                          Forgot Password
                        </Link>
                      </div>
                    </div>

                    <div className="flex mt-5 justify-center">
                      <button
                        className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                        type="submit"
                        style={{ textAlign: "-webkit-center" }}
                        disabled={signing}
                      >
                        {signing ? <BtnSpinner /> : "Login"}
                      </button>
                    </div>
                    {/* <h4 className="text-gray-700 text-center text-sm tracking-normal mt-3">
                  Don’t have an account yet?
                  <Link
                    to="/signup"
                    className="ml-1 text-gray-700 text-center text-sm tracking-normal font-semibold underline"
                  >
                    Create Account
                  </Link>
                </h4> */}
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full md:w-1/2 flex justify-center md:justify-end">
                <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8">
                  <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                    Update your password
                  </h1>
                  <div className="w-full justify-center">
                    <div className="mb-[16px]">
                      <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        New Password
                      </label>
                      <PasswordInput
                        currentPassword={newPassword}
                        onChange={handleNewPasswordChange}
                        onKeyDown={handleKeyDown}
                      />
                      {typeof validations.newPassword != "undefined"
                        ? validations.newPassword.map((e, key) => {
                            return (
                              <span key={key} className="input-error-message">
                                {e}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                    <div className="mb-[16px]">
                      <label className="text-gray-800 text-md font-medium mb-4 leading-[140%]">
                        Confirm Password
                      </label>
                      <PasswordInput
                        currentPassword={newPasswordConfirm}
                        onChange={handleNewPasswordConfirmChange}
                        onKeyDown={handleKeyDown}
                      />
                      {typeof validations.newPasswordConfirm != "undefined"
                        ? validations.newPasswordConfirm.map((e, key) => {
                            return (
                              <span key={key} className="input-error-message">
                                {e}
                              </span>
                            );
                          })
                        : ""}
                    </div>
                  </div>

                  <div className="flex mt-5 justify-center">
                    <button
                      className="bg-main-violate  w-full px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                      // type="submit"
                      onClick={(e) => handleChangePasswordSubmit(e)}
                      style={{ textAlign: "-webkit-center" }}
                      disabled={signing || !formPasswordPassed}
                    >
                      {signing ? <BtnSpinner /> : "Update Password"}
                    </button>
                  </div>
                  {/* <h4 className="text-gray-700 text-center text-sm tracking-normal mt-3">
                  Don’t have an account yet?
                  <Link
                    to="/signup"
                    className="ml-1 text-gray-700 text-center text-sm tracking-normal font-semibold underline"
                  >
                    Create Account
                  </Link>
                </h4> */}
                  {/* </form> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <FooterGuest />
    </div>
  );
};

export default LoginPage;
