import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../Assets/bgAuth.webp";
import config from "../config";
import "../css/login.css";
import Footer from "../Component/Footer";
import BtnSpinner from "../Component/BtnSpinner";
import PasswordInput from "../Component/PasswordInput";
import { BRAND_HIGHR_2 } from "../constants";
import FooterGuest from "../Component/FooterGuest";
import { inputIsPhoneNumberWithCode } from "../helpers";
import { postSignUp } from "../api";

const SignupPage = () => {
  let navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+62");
  const [fullname, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [registering, setRegistering] = useState(false);

  const handleEmailChange = (e) => {
    const value = e.target.value || "";
    setEmail(value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value || "+62";
    inputIsPhoneNumberWithCode(e.target.value) ? setPhone(value) : "+62";
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value || "";
    setUsername(value);
  };

  const handleFullnameChange = (e) => {
    const value = e.target.value || "";
    setFullname(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
  };

  const handleRecaptchaChange = (e) => {
    const value = e;
    setRecaptcha(value);
  };

  const handleSignup = (e) => {
    // if (e.success) {
    //   toast.success(e.message, {
    //     toastId: "signup-success-toast",
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 1250,
    //     onClose: () => navigate("/login", { replace: false }),
    //   });
    // } else {
    //   toast.error(e.message, {
    //     toastId: "signup-failure-toast",
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 1250,
    //   });
    // }

    
    let defaultRedirect = "/dashboard";
    if (e.success) {
      localStorage.removeItem("candidate_invitation");
      sessionStorage.setItem("user", JSON.stringify(e.data));
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (!e.data.profile.phonenumber) {
        defaultRedirect = "/welcome";
      }
      toast.success("Success sign up and logged in as " + user.email + " (" + user.name + ")", {
        toastId: "login-success-toast",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1250,
        onClose: () => navigate(defaultRedirect, { replace: false }),
      });
    } else {
      if (e.status_code === 422) {
        setValidations(e.message);
        toast.error("Validation Error", {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(e.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setRegistering(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRegistering(true);
    const signupData = JSON.stringify({
      // username: username,
      phonenumber: phone,
      // recaptcha: recaptcha,
      email: email,
      name: fullname,
      password: password,
      confirmpassword: confirmPassword,
      job_code: localStorage.getItem("job_code"),
    });

    const result = await postSignUp(signupData);
    handleSignup(result);
  };

  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  useEffect(() => {
    if ("job_code" in localStorage) {
    } else {
      navigate("/login", { replace: true });
    }
  }, []);
  return (
    // <div classNameName="container">
    //   <ToastContainer />
    //   <h1>Create Your Account</h1>
    //   <form name="signup" onSubmit={handleSubmit}>
    //       <input type="email" name="email" id="email" placeholder="Email Address" value={email} onChange={handleEmailChange} required />
    //       <input type="text" name="fullname" id="fullname" placeholder="Full Name" value={fullname} onChange={handleFullnameChange} required />
    //       <input type="password" name="password" id="password" placeholder="Password" value={password} onChange={handlePasswordChange} required />
    //       <input type="password" name="confirmpassword" id="confirmpassword" placeholder="Confirm Password" value={confirmPassword} onChange={handleConfirmPasswordChange} required />
    //       <input type="submit" />
    //   </form>
    // </div>

    <div style={myStyle}>
      <div className="container mx-auto pt-12 h-full flex flex-col md:justify-center">
        <ToastContainer />
        <div className="mx-auto px-4 flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left mb-6">
            <p className="text-xl md:text-2xl text-white-1 mb-[16px] font-normal">
              Welcome To
            </p>
            <p className="text-xl md:text-6xl text-white-1 leading-[110%] font-light md:max-w-sm">
              {BRAND_HIGHR_2}
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <div className="bg-white rounded-lg shadow w-full lg:w-[483px] mb-[100px] px-10 py-8">
              <h1 className="font-bold text-black text-[20px] tracking-[.01em] leading-[125%] mb-6">
                Register To {BRAND_HIGHR_2}
              </h1>
              <form
                className="bg-grey-lightest"
                name="signup"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="w-full justify-center">
                  <div className="mb-3">
                    <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                      Email
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Email"
                      required
                      autoComplete="email"
                    />
                  </div>
                  <div className="mb-3">
                    <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                      Full Name
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                      type="text"
                      name="fullname"
                      id="fullname"
                      value={fullname}
                      onChange={handleFullnameChange}
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                      Username
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                      type="text"
                      name="username"
                      id="username"
                      value={username}
                      onChange={handleUsernameChange}
                      placeholder="Username"
                      required
                      autoComplete="username"
                    />
                  </div> */}
                  <div className="mb-3">
                    <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                      Phone
                    </label>
                    <input
                      className="border w-full p-3 h-10 placeholder:text-sm rounded-lg focus:outline-none focus:border-main-violate border border-line-grey"
                      type="text"
                      name="phone"
                      id="phone"
                      value={phone}
                      onChange={handlePhoneChange}
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                      Password
                    </label>
                    <PasswordInput
                      onChange={(value) => handlePasswordChange(value)}
                    />
                    {password != "" && (
                      <>
                        {password.length < 8 ? (
                          <span className="input-error-message">
                            Password must be at least 8 characters long
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  <div className="mb-[16px]">
                    <label className=" text-gray-800 text-sm font-bold mb-4 leading-[140%]">
                      Confirm Password
                    </label>
                    <PasswordInput
                      placeholder="Confirm Password"
                      onChange={(value) => handleConfirmPasswordChange(value)}
                    />
                    {confirmPassword != "" && (
                      <>
                        {confirmPassword !== password ? (
                          <span className="input-error-message">
                            Password does not match
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {/* <div className="mb-4">
                    <div className="flex justify-start">
                      <div className="form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none focus:border-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          name="agree1"
                          type="checkbox"
                          value="agree1"
                          id="agree1"
                          required
                        />
                      </div>
                      <div>
                        <label
                          className="form-check-label inline-block text-gray-700 text-[12px] leading-[16.8px] tracking-[.01em] text-left"
                          htmlFor="agree1"
                        >
                          I agree that is my responsibility to carry out the
                          test honestly and without the direct/ Indirect
                          assistance of others. I accept consequences in any
                          form from the party if I am proven to have committed a
                          violation.
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-start mt-3 items-center">
                      <div className="form-check">
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-main-violate checked:border-main-violate focus:outline-none focus:border-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value="agree2"
                          name="agree2"
                          id="flexCheckDefault"
                          required
                        />
                      </div>
                      <div>
                        <label
                          className="form-check-label inline-block text-gray-700 text-[12px] leading-[140%] text-left"
                          htmlFor="flexCheckDefault"
                        >
                          I agree on
                        </label>
                        <label
                          className="form-check-label inline-block text-gray-800 text-[12px] font-semibold leading-[140%]  ml-1 underline text-left"
                          htmlFor="flexCheckDefault"
                        >
                          Terms & Conditions
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <ReCAPTCHA
                      sitekey={config.RECAPTCHA_SITE_KEY}
                      onChange={handleRecaptchaChange}
                    />
                  </div> */}
                </div>

                <div className="flex mt-5 mb-4 justify-center">
                  <button
                    className="bg-main-violate  w-[320px] px-4 py-2 text-[16px] leading-[22.4px] text-white font-bold tracking-wider h-[46px] rounded-lg hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
                    type="submit"
                    style={{ textAlign: "-webkit-center" }}
                    disabled={
                      registering ||
                      email === "" ||
                      password === "" ||
                      confirmPassword === "" ||
                      fullname === "" ||
                      phone === "" ||
                      confirmPassword !== password ||
                      password.length < 8
                    }
                  >
                    {registering ? <BtnSpinner /> : "Register"}
                  </button>
                </div>
                <h4 className="text-gray-700 text-center text-sm tracking-normal mt-3">
                  Already have an account?
                  <Link
                    to="/login"
                    className="ml-1 text-gray-700 text-center text-sm tracking-normal font-semibold underline"
                  >
                    Login
                  </Link>
                </h4>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterGuest />
    </div>
  );
};

export default SignupPage;
