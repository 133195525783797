import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bgLogin from "../../Assets/bgAuth.webp";
import "../../css/login.css";
import Footer from "../../Component/Footer";
import BtnSpinner from "../../Component/BtnSpinner";
import { BRAND_HIGHR_2 } from "../../constants";
import FooterGuest from "../../Component/FooterGuest";

const RedirectPage = () => {
  let navigate = useNavigate();
  const [, setSigning] = useState(false);
  const { token } = useParams();
  const checking = () => {
    setSigning(true);
    sessionStorage.removeItem("user");
    axios
      .get(`/api/job-position/${token}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("job_code", token);
          toast.success(response.data.message, {
            toastId: "login-success-toast",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1250,
          });

          setTimeout(() => {
            navigate("/signup", { replace: false });
          }, 2000);
        } else {
          toast.error(response.data.message, {
            toastId: "login-error-toast",
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/login", { replace: false });
          }, 2000);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message, {
          toastId: "login-error-toast",
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/login", { replace: false });
        }, 2000);
      });
  };

  useEffect(() => {
    checking();
  }, []);
  // TEST 2
  const myStyle = {
    backgroundImage: `linear-gradient(242.55deg, rgba(143, 3, 237, 0.7) 0%, rgba(85, 33, 181, 0.7) 99.39%), url(${bgLogin})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle}>
      <div className="container mx-auto pt-12 h-full flex flex-col md:justify-center">
        <ToastContainer />
        <div className="mx-auto px-4 flex w-full flex-col md:flex-row">
          <div className="w-full md:w-1/2 text-center md:text-left mb-6">
            <p className="text-xl md:text-2xl text-white-1 mb-[16px] font-normal">
              Welcome To
            </p>
            <p className="text-xl md:text-6xl text-white-1 leading-[110%] font-light md:max-w-sm">
              {BRAND_HIGHR_2}
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center md:justify-end">
            <div className="bg-white rounded-lg shadow w-full lg:w-[476px] h-[380px] mb-[40px] ">
              <h1 className="pt-[22px] font-bold text-black text-center text-[20px] tracking-[.01em] leading-[125%]">
                Assessment Code Checking
              </h1>
              {/* <p className="mt-3 text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">
              Hello candidate!
            </p> */}

              {/* <p className=" text-gray-800 text-black text-center text-[14px] leading-[140%] tracking-normal">
              Invitation Checking ...
            </p> */}
              <br />
              <div className="h-[65%] flex items-center justify-center">
                <BtnSpinner />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterGuest />
    </div>
  );
};

export default RedirectPage;
