import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
function MouseOutScreen(props) {
  const { onClose } = props;

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  return (
    // Your alert popup component JSX
    <div className="md:px-0 px-2">
      <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[480px] py-2 px-2">
        <ExclamationTriangleIcon
          width={"129px"}
          height={"115px"}
          className="text-secondary-orange"
        />
        <div className="text">
          <p className="font-bold text-center text-lg mb-4">
            Oops! System detected your mouse activity out of screen. Want to
            Continue?
          </p>
          <p className="text-center text-base">
            Your time to do this assessment will be cut by 30 seconds as a
            penalty.
          </p>
        </div>

        <div className="flex mt-5 justify-center w-[100%]">
          {/* <button
          className="border border-line-grey-1 text-grey-98 hover:border-2 cursor-pointer  w-[160px] px-4 py-2 text-[16px] leading-[22.4px] text-black border-line-grey-1 font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed mr-4"
          type="submit"
          onClick={handleClose}
        >
          Never Mind
        </button> */}
          <button
            className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
            type="submit"
            onClick={handleClose}
          >
            I Understand & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default MouseOutScreen;
