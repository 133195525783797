import ReactDOM from "react-dom/client";
import "tw-elements";
import App from "./App";
import "./index.css";
import axios from "axios";
// STORE
import { Provider } from "react-redux";
import store from "./store";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
//PWA
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import eruda from "eruda";

const root = ReactDOM.createRoot(document.getElementById("root"));
const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authentication"] =
  "$2y$10$ix0u7vYSAzYRs2QNOj0lceiogl.6.LtK2r0No2mjhOAZLELWKsPKm";
axios.defaults.headers.common["Authorization"] =
  "user" in sessionStorage
    ? "Bearer " + JSON.parse(sessionStorage.getItem("user")).token
    : "";
axios.interceptors.response.use();
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.removeItem("user");
      // window.location.href = "/login";
      //   const messages = error.response.data.message;
      //   const collapsed = Object.values(messages).reduce(
      //     (acc, curr) => acc.concat(curr),
      //     []
      //   );
    } else {
      return error.response;
    }
  }
);
const radius = 10;

root.render(
  <Provider store={store}>
    <TourProvider
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "#A412FF",
          borderRadius: radius,
        }),
        maskArea: (base) => ({ ...base, rx: radius }),
        controls: (base) => ({ ...base, marginTop: 50 }),
      }}
      nextButton={({
        Button,
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
      }) => {
        const last = currentStep === stepsLength - 1;
        if (last) {
          return (
            <a
              href="#"
              className="text-sm mb-1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Close
            </a>
          );
        } else {
          return (
            <Button
              onClick={() => {
                if (last) {
                  setIsOpen(false);
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
                }
              }}
            ></Button>
          );
        }
      }}
      // steps={steps}
      afterOpen={disableBody}
      beforeClose={enableBody}
    >
      <App />
    </TourProvider>
  </Provider>
);

serviceWorkerRegistration.register();
if (process.env.NODE_ENV === "development") {
  eruda.init();
}
reportWebVitals();
