import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Layout from './Layouts/Layout';
import AppHomePage  from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import SignupPage from './Pages/SignupPage';
import ProfilePage from './Pages/ProfilePage';
import LogoutPage from './Pages/LogoutPage';
import NotFoundPage from './Pages/NotFoundPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import PersonalInformationPage from './Pages/PersonalInformationPage';
import CandidateAppPage from './Pages/CandidateAppPage';
import DashboardProfilePage from './Pages/DashboardProfilePage';
import AssessmentDashboard from './Pages/AssessmentDashboard';
import AssessmentExample from './Pages/AssessmentExample';
import AssessmentTest from './Pages/AssessmentTest';
import PopupPage from './Pages/AssessmentTest/PopupPage';
import AssessmentSetupAccessibility from './Pages/AssessmentSetup/Accessibility';
import AssessmentSetupPreparation from './Pages/AssessmentSetup/Preparation';
import AssessmentSetupDevice from './Pages/AssessmentSetup/Device';
import AssessmentSetupThankYou from './Pages/AssessmentSetup/ThankYou'
import InvitationRedirectPage from './Pages/Invitation/RedirectPage';
import InvitationByJobCodeRedirectPage from './Pages/InvitationByJobCode/RedirectPage';
import CandidateProfileIntroPage from './Pages/CandidateProfileIntroPage';
import JustTest from './Pages/JustTest';
import MainLayout from './Layouts/MainLayout';
import DashboardPage from './Pages/DashboardPage';
import AssessmentLayout from './Layouts/AssessmentLayout';
import QuestionLayout from './Layouts/QuestionLayout';
import HelpsPage from './Pages/HelpsPage';
import AppB2C from './AppB2C';
import InterviewPage from './Pages/InterviewPage';
import ExperimentalPage from './Pages/ExperimentalPage';

const App = () => {
	return (
		<BrowserRouter>
			<Routes>

				{/* ROUTE APPLICATION */}
				<Route path="/" element={<Layout />}>
					<Route index element={<AppHomePage />} />
					<Route path="invitation/candidate/:token" element={<InvitationRedirectPage />} />
					<Route path="invitation/assessment/:token" element={<InvitationByJobCodeRedirectPage />} />
					<Route path="login" element={<LoginPage />} />
					<Route path="welcome" element={<CandidateProfileIntroPage />} />
					<Route path="signup" element={<SignupPage />} />
					<Route path="profile" element={<ProfilePage />} />
					<Route path="logout" element={<LogoutPage />} />
					<Route path="forgot-password" element={<ForgotPasswordPage />} />
					<Route path="reset-password" element={<ResetPasswordPage />} />
					<Route path="personal-information" element={<PersonalInformationPage />} />

					<Route path="*" element={<NotFoundPage />} />
				</Route>

				<Route path="/" element={<MainLayout />}>
					<Route path="dashboard" element={<DashboardPage />} />
					<Route path="candidate/profile" element={<DashboardProfilePage />} />
					<Route path="helps" element={<HelpsPage />} />
					<Route path="/assessment">
						{/* <Route path="preview/pop-up" element={<PopupPage />} /> */}
						<Route path="" element={<AssessmentDashboard />} />
						<Route path=":company_slug/test/:test_id/setup" element={<AssessmentSetupAccessibility />} />
						<Route path=":company_slug/test/:test_id/setup/thank-you" element={<AssessmentSetupThankYou />} />

						{/* <Route path="test/:test_id/setup-device" element={<AssessmentSetupDevice />} /> */}
					</Route>
				</Route>
				{/* ASSESSMENT */}
				<Route path="/assessment/test/:candidate_test_id" element={<AssessmentLayout />}>
					<Route path="setup-device" element={<AssessmentSetupDevice />} />
					<Route path="preparation" element={<AssessmentSetupPreparation />} />
					<Route path="introduction" element={<AssessmentExample />} />
				</Route>
				
				<Route path="/assessment/test/:candidate_test_id/q/:question_id" element={<QuestionLayout />}>
					<Route path="" element={<AssessmentTest />} />
				</Route>


				<Route path="candidate-app" element={<CandidateAppPage />} />
				<Route path="just-test" element={<JustTest />} />

				{/* B2C PAGES */}
				<Route path="user/*" element={<AppB2C />} />

				<Route path="/interview" element={<MainLayout />}>
					<Route path="" element={<InterviewPage />} />
				</Route>

        {/* EXPERIMENT ROUTING */}
        <Route path="/experimental-components" element={<ExperimentalPage />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App;
