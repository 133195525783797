import axios from "axios";
import moment from "moment";

const countries = require("./data/countries.json");
const states = require("./data/states.json");
const cities = require("./data/cities.json");

const validationErrorMessages = {
  required: "This field is required.",
  email: "Please enter a valid email address.",
  password: "Password must be at least 8 characters long.",
  password_confirmation: "Password confirmation must match password.",
  name: "Name must contain only alphabetic characters.",
  phone:
    "Phone number must contain only numeric characters and minimum 9 digits.",
};

export function validationError(key) {
  return validationErrorMessages[key];
}

export function findStateById(id) {
  let state = states.find((state) => id == state.id);
  return state;
}
export function findCountryById(id) {
  let country = countries.find((country) => id == country.id);
  return country;
}
export function findCityById(id) {
  let city = cities.find((city) => id == city.id);
  return city;
}
export async function getEmploymentStatus(user) {
  let employments = await axios
    .get("/api/employment-status", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getIndustryFields(user) {
  let employments = await axios
    .get("/api/industry-fields", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getJobSkills(user) {
  let employments = await axios
    .get("/api/job-skills", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getEducationDegrees(user) {
  let employments = await axios
    .get("/api/helper/education-degree-list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getEducationSkills(user) {
  let employments = await axios
    .get("/api/helper/education-skill-list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export async function getEducationMajors(user) {
  let employments = await axios
    .get("/api/helper/education-major-list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
    .then((response) => {
      const result = response.data;
      if (result.success) {
        return result;
      }
    })
    .catch((e) => {
      return e.response.data;
    });

  return employments;
}

export function inputIsUrlFormat(string) {
  var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  if (urlPattern.test(string)) {
    return true;
  }

  return false;
}

export function inputMaxDate(value = 0) {
  const today = new Date();
  today.setDate(today.getDate() + value);
  return today.toISOString().split("T")[0];
}

export function inputIsOnlyAlphabetic(input) {
  if (input === "") return true;
  var regex = /^[a-zA-Z\s.]+$/;
  var valid = regex.test(input);
  if (!valid) {
    // alert("Name must contain only alphabetic characters.");
    return false;
  } else {
    return true;
  }
}

export function inputIsRupiahFormat(input) {
  if (input === "") return true;
  var regex = /^[1-9][0-9]*$/;
  var valid = regex.test(input);
  if (!valid) {
    // alert("Input must not start with 0.");
    return false;
  } else {
    return true;
  }
}

export function inputIsPhoneNumber(input) {
  if (input === "") return true;
  var regex = /^[0-9+]+$/; // Assumes a 10-digit phone number format
  var valid = regex.test(input);
  if (!valid) {
    // alert("Name must contain only alphabetic characters.");
    return false;
  } else {
    return true;
  }
}

export function inputIsPhoneNumberWithCode(input) {
  if (input === "") return true;

  var regex = /^\+62[0-9]+$/; // Assumes a phone number with '+62' country code format
  var valid = regex.test(input);

  if (!valid) {
    return false;
  } else {
    return true;
  }
}

export function isMobile() {
  if (window.innerWidth < 960) {
    return true;
  } else {
    return false;
  }
}

export function isMobileUserAgent() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function inputIsMinimumAge(threshold = 17, input) {
  var selectedDate = moment(input, "YYYY-MM-DD");
  var currentDate = moment();
  var minimumAge = threshold; // Minimum age in years
  var ageInYears = currentDate.diff(selectedDate, "years");
  if (ageInYears < minimumAge) {
    // alert("You must be at least 17 years old.");
    return false;
  } else {
    return true;
  }
}

export function inputIsEmailFormat(input) {
  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Test the input against the regular expression
  return emailRegex.test(input);
}

export function documentTitle(title) {
  document.title = "Highr ID - " + title;
}

export function isPrime(num) {
  if (num <= 1) return false;
  if (num === 2) return true;
  if (num % 2 === 0) return false;
  for (let i = 3, sqrt = Math.sqrt(num); i <= sqrt; i += 2)
    if (num % i === 0) return false;
  return true;
}

export function capitalizeSentences(str) {
  return str
    .split(". ")
    .map((sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1))
    .join(". ");
}

export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0
) {
  const TO_RADIANS = Math.PI / 180;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export function rupiahFormat(input) {
  if (input === "") return "";
  return "Rp " + input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
