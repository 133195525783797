import axios from "axios";
export const postLogin = async (form) => {
  try {
    const response = await axios.post("/api/login", form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const postSignUp = async (form) => {
  try {
    const response = await axios.post("/api/register-w-code", form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postResetPassword = async (form, tempToken) => {
  try {
    const response = await axios.post("/api/reset-password", form, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tempToken,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postLoginNewPassword = async (form) => {
  try {
    const response = await axios.post("/api/login", form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const postForgotPassword = async (form) => {
  try {
    const response = await axios.post("/api/forgot-password/submit", form, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
