import CameraCapture from "../Component/Experimental/CameraCapture";
import ResizableContainer from "../Component/Experimental/ResizableContainer";

const ExperimentalPage = () => {
  return (
    <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 min-h-[100svh] bg-white p-5 gap-4">
      <div className="flex flex-col gap-2 h-[480px] mb-8">
        <p className="font-semibold text-gray-500">
          Resizable Container Horizontal
        </p>
        <div className="h-full border rounded-lg">
          <ResizableContainer type="horizontal">
            <div className="overflow-y-auto h-full p-4 bg-main-violate-50">
              <h3>Konten Kustom Box Atas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in neque vel nulla dictum cursus ut in ligula. Morbi non arcu ac
                eros aliquet sollicitudin. Praesent sit amet lectus nec felis
                elementum viverra. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nulla in neque vel nulla dictum cursus ut in
                ligula. Morbi non arcu ac eros aliquet sollicitudin. Praesent
                sit amet lectus nec felis elementum viverra.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Nulla in neque vel nulla
                dictum cursus ut in ligula. Morbi non arcu ac eros aliquet
                sollicitudin. Praesent sit amet lectus nec felis elementum
                viverra. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Nulla in neque vel nulla dictum cursus ut in ligula. Morbi
                non arcu ac eros aliquet sollicitudin. Praesent sit amet lectus
                nec felis elementum viverra.
              </p>
            </div>
            <div className="overflow-y-auto h-full p-4 ">
              <h3>Konten Kustom Box Bawah</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in neque vel nulla dictum cursus ut in ligula. Morbi non arcu ac
                eros aliquet sollicitudin. Praesent sit amet lectus nec felis
                elementum viverra. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nulla in neque vel nulla dictum cursus ut in
                ligula. Morbi non arcu ac eros aliquet sollicitudin. Praesent
                sit amet lectus nec felis elementum viverra.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Nulla in neque vel nulla
                dictum cursus ut in ligula. Morbi non arcu ac eros aliquet
                sollicitudin.
              </p>
            </div>
          </ResizableContainer>
        </div>
      </div>

      <div className="flex flex-col gap-2 h-[480px] mb-8">
        <p className="font-semibold text-gray-500">
          Resizable Container Vertical
        </p>
        <div className="h-full border rounded-lg">
          <ResizableContainer type="vertical">
            <div className="overflow-y-auto h-full p-4">
              <h3>Konten Kustom Box Kiri</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in neque vel nulla dictum cursus ut in ligula. Morbi non arcu ac
                eros aliquet sollicitudin. Praesent sit amet lectus nec felis
                elementum viverra. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nulla in neque vel nulla dictum cursus ut in
                ligula. Morbi non arcu ac eros aliquet sollicitudin. Praesent
                sit amet lectus nec felis elementum viverra.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Nulla in neque vel nulla
                dictum cursus ut in ligula. Morbi non arcu ac eros aliquet
                sollicitudin. Praesent sit amet lectus nec felis elementum
                viverra. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Nulla in neque vel nulla dictum cursus ut in ligula. Morbi
                non arcu ac eros aliquet sollicitudin. Praesent sit amet lectus
                nec felis elementum viverra.
              </p>
            </div>
            <div className="overflow-y-auto h-full p-4">
              <h3>Konten Kustom Box Kanan</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in neque vel nulla dictum cursus ut in ligula. Morbi non arcu ac
                eros aliquet sollicitudin. Praesent sit amet lectus nec felis
                elementum viverra. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nulla in neque vel nulla dictum cursus ut in
                ligula. Morbi non arcu ac eros aliquet sollicitudin. Praesent
                sit amet lectus nec felis elementum viverra.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Nulla in neque vel nulla
                dictum cursus ut in ligula. Morbi non arcu ac eros aliquet
                sollicitudin. Praesent sit amet lectus nec felis elementum
                viverra.
              </p>
            </div>
          </ResizableContainer>
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-8">
        <p className="font-semibold text-gray-500">
          iOS Image Capture
        </p>
        <div className="h-full border rounded-lg">
          <CameraCapture />
        </div>
      </div>
    </div>
  );
};

export default ExperimentalPage;
