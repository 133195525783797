import React, { useEffect, useState } from "react";
import PopupContainer from "./PopUp";
import { WindowIcon } from "@heroicons/react/24/solid";
import { ClosedTabPopup } from "./AssessmentPopup";
export default function DevicePermission({ grantAccess }) {
  const [geolocation, setGeolocation] = useState(false);
  // const [notification, setNotification] = useState(false);
  const [mediaDevices, setMediaDevices] = useState(false);
  useEffect(() => {
    // if(geolocation && mediaDevices){
    //   grantAccess();
    // }
    if(mediaDevices){
      grantAccess();
    }
  }, [geolocation, mediaDevices])
  useEffect(() => {
    // Request location permission
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log("Location permission granted.");
          setGeolocation(true);
        },
        function (error) {
          console.log("Location permission denied.");
          setGeolocation(false);
        }
      );
    } else {
      setGeolocation(false);
      console.log("Geolocation is not supported by this browser.");
    }

    // Request device permission (Notification)
    // if ("Notification" in window) {
    //   Notification.requestPermission().then(function (permission) {
    //     if (permission === "granted") {
    //       console.log("Device permission granted.");
    //       setNotification(true);
    //     } else {
    //       console.log("Device permission denied.");
    //       setNotification(false);
    //     }
    //   });
    // } else {
    //   console.log("Notifications are not supported by this browser.");
    //   setNotification(false);
    // }

    // Request media device permissions (microphone, camera)
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (stream) {
          setMediaDevices(true);
          console.log("Media device permission granted.");
        })
        .catch(function (err) {
          setMediaDevices(false);
          console.log("Media device permission denied.");
        });
    } else {
      setMediaDevices(false);
      console.log("MediaDevices is not supported by this browser.");
    }
  }, []);

  return (
    <>
      {/* <PopupContainer>
        <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[420px] py-2 px-4">
          <WindowIcon
            width={"129px"}
            height={"115px"}
            className="text-secondary-orange"
          />
          <div className="text">
            <p className="font-bold text-center text-lg mb-4">
              Allow the following permissions to continue
            </p>
            <p className="text-center text-base">
              Your permission is required to continue access the assessment
              features.
            </p>
          </div>
        </div>
      </PopupContainer> */}
      {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
    </>
  );
}
