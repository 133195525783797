import React, { useState, useRef, useEffect } from "react";

function ResizableContainer({ type = "horizontal", children }) {
  const [size, setSize] = useState(50); // Ukuran awal, 50% untuk memastikan kedua bagian tampil
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const minSize = 100; // Ukuran minimum dalam px untuk bagian yang di-resize

  const handleStartDrag = (e) => {
    e.preventDefault(); // Mencegah event default seperti scroll atau refresh
    setIsDragging(true);
    document.body.style.overflow = "hidden"; // Nonaktifkan scroll halaman saat drag dimulai
  };

  const handleStopDrag = () => {
    setIsDragging(false);
    document.body.style.overflow = ""; // Mengembalikan scroll setelah drag selesai
  };

  // Event untuk Mouse
  const handleMouseMove = (e) => {
    if (isDragging && containerRef.current) {
      updateSize(e.clientX, e.clientY);
      e.preventDefault(); // Mencegah scroll selama dragging
    }
  };

  // Event untuk Touch
  const handleTouchMove = (e) => {
    if (isDragging && containerRef.current) {
      const touch = e.touches[0];
      updateSize(touch.clientX, touch.clientY);
      e.preventDefault(); // Mencegah scroll atau refresh selama dragging
    }
  };

  // Fungsi untuk meng-update ukuran bagian pertama
  const updateSize = (clientX, clientY) => {
    const containerSize =
      type === "horizontal"
        ? containerRef.current.offsetHeight
        : containerRef.current.offsetWidth;

    const newSize =
      type === "horizontal"
        ? ((clientY - containerRef.current.getBoundingClientRect().top) /
            containerSize) *
          100
        : ((clientX - containerRef.current.getBoundingClientRect().left) /
            containerSize) *
          100;

    // Pastikan ukuran tidak kurang dari 50px untuk bagian yang lebih kecil
    if (newSize > 0 && newSize < 100) {
      const minSizePercent = (minSize / containerSize) * 100;
      if (newSize >= minSizePercent && newSize <= 100 - minSizePercent) {
        setSize(newSize);
      }
    }
  };

  // Membersihkan event listener saat resize selesai
  useEffect(() => {
    const stopDragging = () => {
      setIsDragging(false);
      document.body.style.overflow = ""; // Mengembalikan scroll
    };

    window.addEventListener("mouseup", stopDragging);
    window.addEventListener("touchend", stopDragging);
    return () => {
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("touchend", stopDragging);
    };
  }, []);

  // Menangani perubahan layar untuk menyesuaikan ulang tata letak di layar kecil
  useEffect(() => {
    const handleResize = () => {
      // if (window.innerWidth < 768) {
      //   // Atur ulang jika layar terlalu kecil, supaya bagian pertama mengisi 100% dari layar
      //   setSize(50);
      // } else {
      //   // Kembalikan ukuran default jika layar cukup besar
      //   setSize(50);
      // }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Menyesuaikan saat pertama kali halaman dimuat
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Memastikan hanya ada dua children yang diberikan
  const [firstChild, secondChild] = React.Children.toArray(children);

  return (
    <div
      ref={containerRef}
      className={`h-full w-full flex ${
        type === "horizontal" ? "flex-col" : "flex-row"
      } overflow-hidden`}
      onMouseMove={handleMouseMove}
      onTouchMove={handleTouchMove}
      style={{ touchAction: "none" }} // Menambahkan touch-action untuk mencegah scroll
    >
      {/* Bagian pertama (atas atau kiri) dengan overflow-auto */}
      <div
        className={`flex items-center justify-center overflow-auto`}
        style={{ [type === "horizontal" ? "height" : "width"]: `${size}%` }}
      >
        {firstChild}
      </div>

      {/* Garis pemisah yang bisa di-drag dengan area yang lebih besar */}
      <div
        onMouseDown={handleStartDrag}
        onTouchStart={handleStartDrag}
        className={`${
          type === "horizontal"
            ? "w-full h-8 cursor-row-resize bg-[#83838318] flex items-center justify-center rounded-t-lg"
            : "h-full w-8 cursor-col-resize bg-[#83838318] flex items-center justify-center"
        } rounded-sm`}
      >
        <div
          className={`${
            type === "horizontal"
              ? "w-8 h-[3px] bg-gray-600 rounded-full"
              : "h-12 w-1 bg-gray-600 rounded-full"
          }`}
        />
      </div>

      {/* Bagian kedua (bawah atau kanan) */}
      <div
        className={`flex items-center justify-center overflow-auto`}
        style={{
          [type === "horizontal" ? "height" : "width"]: `${100 - size}%`,
        }}
      >
        {secondChild}
      </div>
    </div>
  );
}

export default ResizableContainer;
