import {
  ArrowDownIcon,
  BuildingOfficeIcon,
  FaceSmileIcon,
  FolderOpenIcon,
  HandRaisedIcon,
  MagnifyingGlassIcon,
  ShareIcon,
  SwatchIcon,
} from "@heroicons/react/24/solid";
import PaginationTable from "../../Component/Tables/PaginationTable";
import EmptyContent from "../../Component/Tables/EmptyContent";
import TableSpinner from "../../Component/TableSpinner";
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getMyOccupations } from "../../api";
import { useParams } from "react-router-dom";
import { tab } from "@testing-library/user-event/dist/tab";
import SimpleTable from "../../Component/Tables/SimpleTable";
const CareerProfilePage = () => {
  const [tableLoading, setTableLoading] = useState(true);
  const [occupations, setOccupations] = useState([]);
  const { slug } = useParams();
  const boxStyle = {
    background:
      " var(--Radial-Light-Purple, linear-gradient(92deg, #8F03ED -4.14%, #AC38F6 47.39%, #C96FFF 100%))",
  };

  const handlePaginate = (page) => {};

  // const occupations = [
  //   {
  //     id: 1,
  //     code: "IRE",
  //     occupation_code: "11-2021.00",
  //     occupation: "Marketing Manager",
  //   },
  // ];

  // const result = {
  //   data: occupations,
  //   itemsPerPage: 1,
  //   itemsFrom: 1,
  //   itemsTo: 1,
  //   totalItems: 1,
  //   currentPage: 1,
  // };

  const fetchOccupations = async () => {
    setTableLoading(true);
    const result = await getMyOccupations(slug);
    if (result.success) {
      setOccupations(result.data);
    } else {
      toast.error(result.message, {
        toastId: "login-error-toast",
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setTableLoading(false);
  };

  const isRedirectIg = (value) => {
    if (
      value?.occupation?.skills.length === 0 ||
      value?.occupation.abilities.length === 0 ||
      value?.occupation.knowledge.length === 0 ||
      value?.occupation.knowledge.length === 0 ||
      value?.occupation.personality.length === 0 ||
      value?.occupation.technology.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchOccupations();
  }, []);
  const TableContent = () => {
    if (tableLoading) {
      return <TableSpinner colSpan={6} />;
    } else {
      return (
        <>
          {occupations.length > 0 ? (
            <>
              {occupations.map((value) => (
                <tr className="whitespace-nowrap border-b-[1px]">
                  {/* <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">{value.code}</div>
                </td> */}
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {value.occupation
                        ? value.occupation.occupation_code
                        : "-"}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900 font-bold">
                      {/* <a
                      href={`/user/${slug}/reports/${value.occupation_id}`}
                      className="font-bold underline"
                    > */}
                      {value.raw_occupation_name}
                      {/* </a> */}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <button
                      className="py-1 px-2 border-main-violate rounded-lg border text-main-violate font-medium flex items-center gap-2 hover:bg-main-violate-600 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-main-violate-100 disabled:hover:text-main-violate"
                      // disabled={value.occupation == null}
                      onClick={() => {
                        window.open("https://highr.id/articles/kenali-tes-bakat-riasec-cari-potensi-karier-lewat-kepribadian", '_blank');
                        // if (isRedirectIg(value)) {
                        // } else {
                        // value.occupation &&
                        //   (window.location.href = `/user/${slug}/reports/${value?.occupation.occupation_id}`);
                        // }
                      }}
                    >
                      Read more
                      {/* {isRedirectIg(value)
                        ? "Read article for detail"
                        : "Read article for detail"} */}
                    </button>
                    {/* <div className="text-sm text-gray-900">
                    <a
                      href={`/user/${slug}/reports/${value.occupation_id}`}
                      className="font-bold underline"
                    >
                      {value.occupation_name}
                    </a>
                  </div> */}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <EmptyContent />
          )}
        </>
      );
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="h-[310px] w-full" style={boxStyle}>
        <div className="mdb:max-w-[70%] px-3 mdb:px-0 mdb:mx-auto pt-11">
          <p className="text-[30px] mdb:text-[60px] font-extrabold text-white ">
            Your Career Profile
          </p>
        </div>
      </div>
      <div className="min-h-[200px] w-full bg-white">
        <div className="px-3 mdb:px-0 mdb:max-w-[70%] mx-auto relative top-[-200px] mdb:top-[-150px] mb-4 mdb:mb-0">
          <div className="border border-lg border-main-violate rounded-md">
            <div className="bg-main-violate-100 rounded-tl-md rounded-tr-md p-6">
              {!tableLoading && (
                <p className="text-xl text-main-violate font-semibold mb-4">
                  {occupations[0].bridge.toUpperCase()}
                  {/* {occupations.data[0]?.bridge
                    .replace(/_/g, ", ")
                    .toUpperCase()} */}
                </p>
              )}

              <p className="mb-4">
                Get better at navigating the professional world and achieving
                your goals based on your interests.
              </p>
              <div className="flex space-x-5">
                <button
                  className="py-2 px-3 border-main-violate rounded-lg border text-main-violate font-bold flex items-center gap-2 hover:bg-main-violate-600 hover:text-white"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    toast.success("Copied To Clipboard", {
                      toastId: "login-success-toast",
                      position: toast.POSITION.TOP_CENTER,
                    });
                  }}
                >
                  Share <ShareIcon width={16} />
                </button>
                {/* <button className="py-2 px-3 border-main-violate rounded-lg border text-main-violate font-bold flex items-center gap-2 hover:bg-main-violate-600 hover:text-white">
                  Download <ArrowDownIcon width={16} />
                </button> */}
              </div>
            </div>
            <div className="bg-white rounded-md p-6">
              <SimpleTable
                checkbox={false}
                headers={["OCCUPATION CODE", "OCCUPATION", "ACTION"]}
                content={<TableContent />}
              />
              {/* <PaginationTable
                checkbox={false}
                currentPage={handlePaginate}
                itemsTo={occupations.to}
                itemsFrom={occupations.from}
                itemsPerPage={5}
                totalItems={occupations.total}
                totalPages={Math.ceil(occupations.total / occupations.per_page)}
                headers={["OCCUPATION CODE", "OCCUPATION", "ACTION"]}
                content={<TableContent />}
              /> */}
            </div>
          </div>
        </div>
        <div className="px-3 mdb:px-0 mdb:max-w-[70%] top-[-200px] mdb:top-[-100px] mx-auto relative ">
          <div className="border-main-violate rounded-md">
            <div className="bg-main-violate-100 rounded-md p-6">
              <p className="text-xl font-semibold mb-1 text-gray-900">
                About All Interests
              </p>
              <p className="mb-4 text-gray-900">
                Interests are preferences for work environments and outcomes.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-1">
                <div className="card-mini flex space-x-3">
                  <div className="w-[100px] b2c about-realistic h-[100px] flex justify-center items-center">
                    <HandRaisedIcon color="white" width={40} />
                  </div>
                  <div className="w-[75%]">
                    <p className="text-lg font-semibold mb-3">Realistics</p>
                    <p className="text">
                      Explore your desire for physical work and if you’d like
                      working with your hands.
                    </p>
                  </div>
                </div>
                <div className="card-mini flex space-x-3">
                  <div className="w-[100px] b2c about-investigative h-[100px] flex justify-center items-center">
                    <MagnifyingGlassIcon color="white" width={40} />
                  </div>
                  <div className="w-[75%]">
                    <p className="text-lg font-semibold mb-3">Investigative</p>
                    <p className="text">
                      Measure your preference for solving complex, abstract
                      problems at work.
                    </p>
                  </div>
                </div>
                <div className="card-mini flex space-x-3">
                  <div className="w-[100px] b2c about-artistics h-[100px] flex justify-center items-center">
                    <SwatchIcon color="white" width={40} />
                  </div>
                  <div className="w-[75%]">
                    <p className="text-lg font-semibold mb-3">Artistics</p>
                    <p className="text">
                      Assess whether you bring outside-the-box thinking and
                      individuality to your work.
                    </p>
                  </div>
                </div>
                <div className="card-mini flex space-x-3">
                  <div className="w-[100px] b2c about-socials h-[100px] flex justify-center items-center">
                    <FaceSmileIcon color="white" width={40} />
                  </div>
                  <div className="w-[75%]">
                    <p className="text-lg font-semibold mb-3">Socials</p>
                    <p className="text">
                      See how much you’d enjoy working with others and helping
                      them improve.
                    </p>
                  </div>
                </div>
                <div className="card-mini flex space-x-3">
                  <div className="w-[100px] b2c about-enterprising h-[100px] flex justify-center items-center">
                    <BuildingOfficeIcon color="white" width={40} />
                  </div>
                  <div className="w-[75%]">
                    <p className="text-lg font-semibold mb-3">Enterprising</p>
                    <p className="text">
                      Discover your preference for setting courses of action and
                      convincing others.
                    </p>
                  </div>
                </div>
                <div className="card-mini flex space-x-3">
                  <div className="w-[100px] b2c about-conventional h-[100px] flex justify-center items-center">
                    <FolderOpenIcon color="white" width={40} />
                  </div>
                  <div className="w-[75%]">
                    <p className="text-lg font-semibold mb-3">Conventional</p>
                    <p className="text">
                      See how much of your professional life is guided by
                      procedure and routine.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerProfilePage;
