import React from "react";

function PopUp({ children }) {
  return (
    <>
      <div className="popup-alert justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate" style={{background: "#7a727240", zIndex: "999"}}>
        
        <div className="relative w-auto my-6 mx-auto max-w-3xl">{children}</div>
      </div>
    </>
  );
}
export default PopUp;
