import React, { useRef, useState, useEffect } from "react";

const CameraCapture = ({ stream, onCaptured }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (stream) {
      videoRef.current.srcObject = stream;
      setTimeout(() => {
        takePhoto(stream);
      }, 2000);
    }
  }, [stream]);

  const takePhoto = async (stream) => {
    if ("ImageCapture" in window) {
      const track = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(track);
      const capturedFrame = await imageCapture.takePhoto();
      const blob = capturedFrame;
      onCaptured(blob);
      setPhoto(dataURL);
      setHasPhoto(true);
    } else {
      const width = videoRef.current.videoWidth;
      const height = videoRef.current.videoHeight;
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      const context = canvasRef.current.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, width, height);
      const imageDataUrl = canvasRef.current.toDataURL("image/jpeg", 0.6);
      const blob = dataURLToBlob(imageDataUrl);
      setPhoto(imageDataUrl); // Atau simpan blob jika ingin mengupload
      setHasPhoto(true);
      onCaptured(blob);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const parts = dataURL.split(",");
    const byteString = atob(parts[1]);
    const mimeString = parts[0].match(/:(.*?);/)[1];

    const arrayBuffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };

  const blobToDataURL = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const resetPhoto = () => {
    setPhoto(null);
    setHasPhoto(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      {/* <h2>Camera Capture</h2> */}
      <div style={{ display: hasPhoto ? "none" : "block" }}>
        <video
          muted
          ref={videoRef}
          autoPlay
          playsInline
          style={{ width: "100%", maxWidth: "400px", display: "none" }}
        ></video>
        {/* <button
          className="bg-main-violate w-full px-4 text-[16px] text-white font-semibold h-[46px] rounded-lg mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
          onClick={takePhoto}
          style={{ marginTop: "10px" }}
        >
          Take Photo
        </button> */}
      </div>

      {/* <div style={{ display: hasPhoto ? "block" : "none" }}> */}
      {/* {photo && (
          <img
            src={photo}
            alt="Captured"
            style={{ width: "100%", maxWidth: "400px" }}
          />
        )} */}
      {/* <button
          className="bg-main-violate w-full px-4 text-[16px] text-white font-semibold h-[46px] rounded-lg mt-4 hover:bg-violet-600 disabled:bg-violet-300 disabled:cursor-not-allowed"
          onClick={resetPhoto}
          style={{ marginTop: "10px" }}
        >
          Retake Photo
        </button> */}
      {/* </div> */}
      {/* Canvas hanya digunakan untuk mengambil gambar, tidak perlu ditampilkan */}
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
    </div>
  );
};

export default CameraCapture;
