import React from "react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
function FinishedTestPopup(props) {
  const { onClose } = props;

  const handleClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };
  return (
    // Your alert popup component JSX
    <div className="md:px-0 px-2">
      <div className="bg-white rounded-lg shadow lg:w-[480px] outline-none focus:rounded-lg focus:outline-none focus:ring-main-violate flex flex-col items-center justify-evenly h-[380px]">
        <CheckBadgeIcon
          width={"130px"}
          height={"130px"}
          className="text-main-violate"
        />
        <div className="text px-2 px-2">
          <p className="font-bold text-center text-lg mb-4">Well Done</p>
          <p className="text-center text-base">
            Congrats! You have finished the personality test. Please check any
            unfinished task on assesment list.
          </p>
        </div>

        <div className="flex mt-5 justify-center w-[100%]">
          <button
            className="hover:bg-violet-600 cursor-pointer bg-main-violate  px-4 py-2 text-[16px] leading-[22.4px] text-white font-semibold tracking-normal h-[46px] rounded-lg mt-5 disabled:bg-violet-300 disabled:cursor-not-allowed"
            type="submit"
            onClick={handleClose}
          >
            Back To Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}

export default FinishedTestPopup;
