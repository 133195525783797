import { useSelector } from "react-redux";

const AssessmentProgressBar = () => {
  const { current, question, test } = useSelector(
    (state) => state.assessment.activeTest
  );
  return (
    <div className={`${!question ? 'hidden' : 'block' } max-h-max flex justify-between flex-col-reverse md:flex-row w-full md:px-4 px-2 py-2 bg-[#FFFFFFFF] `}>
      <div className="md:mt-0 w-full">
        <div className="w-full h-[6px] md:h-[12px] bg-white-1 rounded-full">
          <div
            className="h-full text-center text-xs text-white bg-main-violate rounded-full"
            style={{
              width: `${
                ((question?.variant == "group" ? current : current) /
                  test?.total_question) *
                100
              }%`,
            }}
          ></div>
        </div>
        <div className="flex mt-2">
          <p className="font-semibold text-gray-700 text-[14px] leading-[140%] text-left">
            Question :{" "}
            {(
              ((question?.variant == "group" ? current : current) /
                test?.total_question) *
              100
            ).toFixed(0)}
            %
          </p>
          <p className="text-gray-400 text-[14px] leading-[140%] text-left ml-[4px]">
            ({question?.variant == "group" ? current : current}/
            {test?.total_question} Completed)
          </p>
        </div>
      </div>
    </div>
  );
};

export default AssessmentProgressBar;
