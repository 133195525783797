import React, { Component, createRef } from "react";
import IcOffice from "../../Assets/Office.png";
import PencilSimpleLine from "../../Assets/PencilSimpleLine.png";
import "./index.css";
import moment from "moment";
import { MicrophoneIcon, SpeakerWaveIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import sound1 from "../../Assets/sounds/sound1.mp3"
import PropTypes from 'prop-types';

export class CameraView extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
    this.state = {
      cameras: [],
      speakers: [],
      mics: [],
      stream: null,
      speaker: null,
      mic: null,
      camera: null,
      mediaSource: null,
      sourceBuffer: null,
      volume: 0
    };
  }

  componentDidMount = () => {
    // this.getDevices();
    this.createStream();

  }

  componentWillUnmount = () => {
    try {
      setTimeout(() => {
        this.state.stream.getTracks().forEach(track => {
          track.stop()
        })
        this.setState({ stream: null });
      }, 2000)

    } catch (error) {
      
      // console.log(error)
    }

  }

  handleEdit = (e, id) => {
    this.props.onShowEdit(id)
  };

  getDevices = async () => {
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.setState({ cameras: devices.filter((device) => device.kind === "videoinput") }, () => {
        // SET DEFAULT IF USER NOT SELECTED DEVICE
        // this.props.selectedCamera(this.state.cameras[0])
      });
      this.setState({ mics: devices.filter((device) => device.kind === "audioinput") }, () => {
        // SET DEFAULT IF USER NOT SELECTED DEVICE
        // this.props.selectedMic(this.state.mics[0])
      });
      this.setState({ speakers: devices.filter((device) => device.kind === "audiooutput") }, () => {
        // SET DEFAULT IF USER NOT SELECTED DEVICE
        // this.props.selectedSpeaker(this.state.speakers[0])
      });
    });
  }
  createStream = () => {
    try {
      if (this.state.stream) {
        // setTimeout(() => {
        //   this.state.stream.getTracks().forEach(track => {
        //     track.stop()
        //   })
        //   this.setState({ stream: null });
        // }, 2000)
      }
      this.setState({ speaker: this.props.defaultSpeaker ? this.props.defaultSpeaker : "default" });
      this.setState({ mic: this.props.defaultMic ? this.props.defaultMic : "default" });
      this.setState({ camera: this.props.defaultCamera ? this.props.defaultCamera : "default" });
      let constraints = {
        audio: {
          deviceId: this.state.mic,
        },
        video: {
          deviceId: this.state.camera,
        },
      };

      var mediaSource = new MediaStream();
      mediaSource.addEventListener("sourceopen", this.handleSourceOpen, false);
      this.setState({ mediaSource: mediaSource });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.successCallback, this.errorCallback);
    } catch (error) {
    }

  }

  successCallback = (stream) => {
    this.getDevices();
    this.videoRef.current.srcObject = stream;
    this.setState({ stream: stream });
    this.videoRef.current.play();
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyzer = audioContext.createAnalyser();
    source.connect(analyzer);
    analyzer.fftSize = 32; // Number of frequency bins for analysis
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const updateVolume = () => {
      analyzer.getByteFrequencyData(dataArray);
      const averageVolume = [...dataArray].reduce((a, b) => a + b, 0) / bufferLength;
      this.setState({ volume: averageVolume > 150 ? 150 : averageVolume });
      requestAnimationFrame(updateVolume);
    };
    updateVolume();
  };

  errorCallback = (error) => {
    
  };

  playSound = async () => {
    const audioContext = new AudioContext();
    const destination = audioContext.createMediaStreamDestination();
    const mediaStreamSource = audioContext.createMediaStreamSource(this.state.stream);
    mediaStreamSource.connect(destination);
    const audioElement = new Audio(sound1);
    await audioElement.setSinkId(this.state.speaker);
    audioElement.play();
  };

  handleSourceOpen = (event) => {
    this.setState({ sourceBuffer: this.state.mediaSource.addSourceBuffer("video/mp4") });
  };

  handleChangeCamera = (deviceId) => {
    this.setState({ camera: deviceId }, () => {
      this.createStream();
      this.props.selectedCamera(this.state.cameras.find((camera) => camera.deviceId === deviceId))
    });
  };

  handleChangeMic = (deviceId) => {
    this.setState({ mic: deviceId }, () => {
      this.createStream();
      this.props.selectedMic(this.state.mics.find((mic) => mic.deviceId === deviceId))
    });
  };

  handlChangeSpeaker = (deviceId) => {
    this.setState({ speaker: deviceId }, () => {
      this.createStream();
      this.props.selectedSpeaker(this.state.speakers.find((speaker) => speaker.deviceId === deviceId))
    });
  };

  render() {
    // const { selectedCamera, selectedMic, selectedSpeaker } = this.props;

    return (
      <div>
        <div className="flex flex-col-reverse sm:flex-row">
          <div className="w-[100%] sm:w-1/2 mb-3 sm:mb-0">
            <video
              className="max-h-[30svh] sm:max-h-[300px] w-[100%] sm:w-[90%]"
              autoPlay
              muted
              playsInline
              ref={this.videoRef}
            />
            <div className="mt-2">
              <p className="text-danger-main cursor-pointer text-xs underline font-bold">
                Report Problem
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 flex flex-row sm:flex-col gap-2">
            <div className="mb-4 w-1/2 sm:w-full">
              <label className="text-gray-800 text-sm font-bold mb-3 leading-[140%] flex font-bold">
                <VideoCameraIcon className="w-[18px] md:w-[20px] mr-2" /> Camera
              </label>
              <select
                className="form-select appearance-none block w-full px-3py-2 text-soft-grey text-[14px] bg-white border border-solid border-gray-300 rounded-md transition ease-in-out  m-0 focus:border-main-violate"
                aria-label="Default select example"
                name="city"
                onChange={(e) => this.handleChangeCamera(e.target.value)}
              >
                <option selected disabled>
                  Select Camera
                </option>
                {this.state.cameras.map((e, key) => {
                  if (key == 0) {
                    return (
                      <option key={key} selected value={e.deviceId}>
                        {e.label}
                      </option>
                    );
                  } else {
                    return (
                      <option key={key} value={e.deviceId}>
                        {e.label}
                      </option>
                    );
                  }

                })}
              </select>
            </div>
            <div className="mb-4 w-1/2 sm:w-full">
              <label className="text-gray-800 text-sm font-bold mb-3 leading-[140%] flex font-bold">
                <MicrophoneIcon className="w-[18px] md:w-[20px] mr-2" />{" "}
                Microphone
              </label>
              <select
                className="form-select appearance-none block w-full px-3py-2 text-soft-grey text-[14px] bg-white border border-solid border-gray-300 rounded-md transition ease-in-out  m-0 focus:border-main-violate"
                aria-label="Default select example"
                name="city"
                onChange={(e) => this.handleChangeMic(e.target.value)}
              >
                <option selected disabled>
                  Select Microphone
                </option>
                {this.state.mics.map((e, key) => {
                  if (key == 0) {
                    return (
                      <option key={key} selected value={e.deviceId}>
                        {e.label}
                      </option>
                    );
                  } else {
                    return (
                      <option key={key} value={e.deviceId}>
                        {e.label}
                      </option>
                    );
                  }

                })}
              </select>
              <div className="w-full h-[7px] bg-white-1 rounded-full mt-2">
                <div
                  className="h-full text-center text-xs text-white bg-main-violate rounded-full"
                  style={{
                    width: `${(this.state.volume / 150) * 100}%`,
                  }}
                >

                </div>
              </div>
            </div>
            <div className="mb-4 w-1/2 sm:w-full">
              <label className="text-gray-800 text-sm font-bold mb-3 leading-[140%] flex font-bold">
                <SpeakerWaveIcon className="w-[18px] md:w-[20px] mr-2" />{" "}
                Speaker
              </label>
              <select
                className="form-select appearance-none block w-full px-3py-2 text-soft-grey text-[14px] bg-white border border-solid border-gray-300 rounded-md transition ease-in-out  m-0 focus:border-main-violate"
                aria-label="Default select example"
                name="city"
                onChange={(e) => this.handlChangeSpeaker(e.target.value)}
              >
                <option selected disabled>
                  Select Speaker
                </option>
                {this.state.speakers.map((e, key) => {
                  if (key == 0) {
                    return (
                      <option key={key} selected value={e.deviceId}>
                        {e.label}
                      </option>
                    );
                  } else {
                    return (
                      <option key={key} value={e.deviceId}>
                        {e.label}
                      </option>
                    );
                  }
                })}
              </select>
              <div className="mt-2" onClick={this.playSound}>
                <p className="cursor-pointer text-xs underline font-bold leading-[0.2]">
                  Test Speaker
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

// CameraView.prototype = {
//   selectedCamera: PropTypes.object,
//   selectedMic: PropTypes.object,
//   selectedSpeaker: PropTypes.object,
// }

export default CameraView;
